var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_receivables_mutation_customer_report") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            "label-col": { span: 6 },
            "wrapper-col": { span: 12 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateForm.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.currency.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.currency.decorator,
                              expression: "formRules.currency.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.currency.name,
                            placeholder: _vm.$t(
                              _vm.formRules.currency.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingCurrency,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCurrency(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataCurrency, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(data.currencyCode) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s(data.currencyCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.customerName.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingCustomerName,
                            allowClear: true,
                            "dropdown-match-select-width": false
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCustomerName(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataCustomerName, function(data) {
                          return _c(
                            "a-select-option",
                            { key: data.key, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.label) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.label) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.branch.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingBranch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.paymentStatus.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.paymentStatus.decorator,
                              expression: "formRules.paymentStatus.decorator"
                            }
                          ],
                          attrs: {
                            placeholder: _vm.$t(
                              _vm.formRules.paymentStatus.placeholder
                            ),
                            allowClear: true
                          }
                        },
                        _vm._l(_vm.optPaymentStatus, function(data) {
                          return _c(
                            "a-select-option",
                            { key: data.key, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.label) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.label) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.invoiceType.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.invoiceType.decorator,
                              expression: "formRules.invoiceType.decorator"
                            }
                          ],
                          attrs: {
                            placeholder: _vm.$t(
                              _vm.formRules.invoiceType.placeholder
                            ),
                            allowClear: true
                          }
                        },
                        _vm._l(_vm.optInvoiceType, function(data) {
                          return _c(
                            "a-select-option",
                            { key: data.key, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.label) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.label) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.invoiceDate.label) }
                    },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.invoiceDate.decorator,
                            expression: "formRules.invoiceDate.decorator"
                          }
                        ],
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.paymentDate.label) }
                    },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.paymentDate.decorator,
                            expression: "formRules.paymentDate.decorator"
                          }
                        ],
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleReset } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.find,
                            type: "primary",
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.dataSource,
            columns: _vm.columns,
            pagination: {
              showTotal: function(total) {
                return _vm.$t("lbl_total_items", { total: total })
              },
              showSizeChanger: true
            },
            loading: _vm.loading.find,
            scroll: { x: 2500 },
            size: "small"
          },
          scopedSlots: _vm._u([
            {
              key: "currency",
              fn: function(text) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(text)))
                ])
              }
            },
            {
              key: "date",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
              }
            },
            {
              key: "nullable",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
              }
            }
          ])
        },
        [
          _c(
            "a-descriptions",
            { attrs: { slot: "title", size: "small" }, slot: "title" },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_beginning_balance") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataMutation.beginningBalance)
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-descriptions",
            { attrs: { slot: "footer", size: "small" }, slot: "footer" },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_total") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.dataMutation.total)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_ending_balance") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataMutation.endingBalance)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_total_paid_amount") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.dataMutation.totalPaidAmount)
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2 text-right" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading.download },
              on: { click: _vm.handleDownload }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }