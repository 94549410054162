













































































































































































































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { Row, useContactData, useDate, useInvoiceAR } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ReceivablesMutationLineReportDTO,
  ReceivablesMutationReportDTO,
} from "@/models/interface/invoice.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { invoiceServices } from "@/services/invoice.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Moment } from "moment";
import { Component, Mixins } from "vue-property-decorator";

type TableRow = Row<ReceivablesMutationLineReportDTO>;
type FormValue = {
  branch: string;
  currency: string;
  customerName: string;
  paymentStatus: string;
  invoiceType: string;
  invoiceDate: Array<Moment>;
  paymentDate: Array<Moment>;
};

@Component
export default class ReceivableMutationReport extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form!: WrappedFormUtils;

  loading = {
    loadingBastNumber: false,
    download: false,
    loadingCustomerName: false,
    loadingDownload: false,
    loadingCurrency: false,
    loadingBranch: false,
    find: false,
  };

  formRules = {
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    paymentStatus: {
      label: "lbl_payment_status",
      placeholder: "lbl_payment_status",
      decorator: ["paymentStatus"],
    },
    invoiceType: {
      label: "lbl_invoice_type",
      placeholder: "lbl_invoice_type",
      decorator: ["invoiceType"],
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      placeholder: "lbl_invoice_date",
      decorator: ["invoiceDate"],
    },
    paymentDate: {
      label: "lbl_payment_date",
      placeholder: "lbl_payment_date",
      decorator: ["paymentDate"],
    },
  };

  dataSource: Array<TableRow> = [];

  columns = [
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerNumber",
      key: "customerCode",
      scopedSlots: { customRender: "nullable" },
      fixed: "left",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      key: "invoiceNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_tax_invoice"),
      dataIndex: "taxInvoiceNo",
      key: "taxInvoice",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_rate"),
      dataIndex: "currencyRate",
      key: "rate",
      width: "75px",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_top"),
      dataIndex: "termOfPayment",
      key: "top",
      width: "75px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_due_date"),
      dataIndex: "dueDate",
      key: "dueDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_dpp"),
      dataIndex: "dpp",
      key: "dpp",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_ppn"),
      dataIndex: "ppn",
      key: "ppn",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_payment_number"),
      dataIndex: "arNumber",
      key: "paymentNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_transaction_date"),
      dataIndex: "arDate",
      key: "transactionDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_payment_value"),
      dataIndex: "arPaidAmount",
      key: "paymentValue",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_write_off_amount"),
      dataIndex: "writeOffAmount",
      key: "writeOffAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_additional_amount"),
      dataIndex: "additionalAmount",
      key: "additionalAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_invoice_balance"),
      dataIndex: "invoiceBalance",
      key: "invoiceBalance",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_ending_balance"),
      dataIndex: "endingBalance",
      key: "endingBalance",
      scopedSlots: { customRender: "currency" },
    },
  ];

  dataCustomerName: Array<Option<ContactData>> = [];
  dataCurrency: DataMasterCurrency[] = [];
  dataBranch: DataWarehouseBranch[] = [];
  optPaymentStatus: Array<Option> = [];
  optInvoiceType: Array<Option> = [];

  dataMutation: ReceivablesMutationReportDTO = {
    beginningBalance: 0,
    endingBalance: 0,
    receivablesMutationLines: [],
    total: 0,
    totalBalance: 0,
    totalPaidAmount: 0,
  };

  beforeCreate(): void {
    this.form = this.$form.createForm(this, {
      name: "ReceivableMutationReport",
    });
  }

  mounted() {
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getCustomerName = debounceProcess(this.getCustomerName, 200);
    this.getCurrency = debounceProcess(this.getCurrency, 400);

    this.getBranch("");
    this.getCustomerName("");
    this.getCurrency("");
    this.fetchInvoiceArStatus();
    this.fetchInvoiceType();
  }

  getBranch(valueSearch = ""): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.loadingBranch = false));
  }
  getCurrency(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `currencyCode~${valueSearch}`;
    this.loading.loadingCurrency = true;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then(response => (this.dataCurrency = response.data))
      .finally(() => (this.loading.loadingCurrency = false));
  }
  getCustomerName(search = ""): void {
    const { findCustomers, toOptionsNameCode } = useContactData();
    const params = new RequestQueryParams();
    if (search) {
      params.search = new SearchBuilder()
        .push(["firstName", search], { like: "both" })
        .or()
        .push(["lastName", search], { like: "both" })
        .or()
        .push(["customerNumber", search], { like: "both" })
        .build();
    }
    this.loading.loadingCustomerName = true;
    findCustomers(params)
      .then(response => {
        this.dataCustomerName = toOptionsNameCode(response);
      })
      .finally(() => {
        this.loading.loadingCustomerName = false;
      });
  }

  buildParams(res: FormValue): string {
    const company = this.$store.state.authStore.authData.companyName;
    const branchName =
      this.dataBranch.find(e => e.id === res.branch)?.name || "ALL";
    const customerName =
      this.dataCustomerName.find(e => e.value === res.customerName)?.meta
        ?.fullName || "ALL";
    const currencyCode =
      this.dataCurrency.find(e => e.id === res.currency)?.currencyCode || "ALL";
    const paymentStatus = res.paymentStatus || "ALL";
    const invoiceType = res.invoiceType || "ALL";
    const startTransDate =
      res.invoiceDate && res.invoiceDate.length
        ? res.invoiceDate[0].format(DEFAULT_DATE_FORMAT)
        : "ALL";
    const endTransDate =
      res.invoiceDate && res.invoiceDate.length
        ? res.invoiceDate[1].format(DEFAULT_DATE_FORMAT)
        : "ALL";
    const startDueDate =
      res.paymentDate && res.paymentDate.length
        ? res.paymentDate[0].format(DEFAULT_DATE_FORMAT)
        : "ALL";
    const endDueDate =
      res.paymentDate && res.paymentDate.length
        ? res.paymentDate[1].format(DEFAULT_DATE_FORMAT)
        : "ALL";

    const params: Array<string> = [
      company,
      branchName,
      customerName,
      currencyCode,
      startTransDate,
      endTransDate,
      paymentStatus,
      invoiceType,
      startDueDate,
      endDueDate,
    ];

    return params.join(",");
  }

  handleReset() {
    this.form.resetFields();
  }

  handleDownload(): void {
    this.form.validateFields((err, res: FormValue) => {
      if (err) {
        this.showNotifError("lbl_validation_required_error");
        return;
      }

      const params: RequestQueryParamsModel = {
        sorts: "invoiceDate:asc,id:asc",
        search: this.buildSearch(res),
        params: this.buildParams(res),
      };

      if (res.customerName) {
        params.customerId = res.customerName;
      }

      const { toStartDay, toEndDay } = useDate();
      if (res.invoiceDate && res.invoiceDate.length) {
        const [start, end] = res.invoiceDate;
        params.invoiceDateFrom = toStartDay(start).format();
        params.invoiceDateTo = toEndDay(end).format();
      }

      if (res.paymentDate && res.paymentDate.length) {
        const [start, end] = res.paymentDate;
        params.paymentDateFrom = toStartDay(start).format();
        params.paymentDateTo = toEndDay(end).format();
      }

      this.loading.download = true;
      invoiceServices
        .downloadReportReceivableMutation(params)
        .then(response => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "receivable_mutation_report.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loading.download = false));
    });
  }

  buildSearch(value: FormValue): string {
    const { toEndDay, toStartDay } = useDate();
    const builder = new SearchBuilder();
    const q: Array<string> = [];
    const orQuery: Array<string> = [];

    if (value.branch) {
      q.push(builder.push(["branchId", value.branch]).build());
      builder.destroy();
    }

    if (value.currency) {
      q.push(builder.push(["currencyId", value.currency]).build());
      builder.destroy();
    }

    if (value.customerName) {
      q.push(builder.push(["customerId", value.customerName]).build());
      builder.destroy();
    }

    if (value.paymentStatus) {
      q.push(
        builder
          .push(["paymentStatus", value.paymentStatus], { like: "both" })
          .build()
      );
      builder.destroy();
    }

    if (value.invoiceType) {
      q.push(
        builder
          .push(["invoiceType", value.invoiceType], { like: "both" })
          .build()
      );
      builder.destroy();
    }

    return q.join(builder.AND);
  }

  validateForm(): void {
    this.form.validateFields((err, value: FormValue) => {
      if (err) {
        this.showNotifError("lbl_validation_required_error");
        return;
      }

      const params = new RequestQueryParams();
      params.sorts = "invoiceDate:asc,id:asc";
      params.search = this.buildSearch(value);
      const { toStartDay, toEndDay } = useDate();

      if (value.customerName) {
        params.customerId = value.customerName;
      }

      if (value.invoiceDate && value.invoiceDate.length) {
        const [start, end] = value.invoiceDate;
        params.invoiceDateFrom = toStartDay(start).format();
        params.invoiceDateTo = toEndDay(end).format();
      }

      if (value.paymentDate && value.paymentDate.length) {
        const [start, end] = value.paymentDate;
        params.paymentDateFrom = toStartDay(start).format();
        params.paymentDateTo = toEndDay(end).format();
      }

      this.handleFind(params);
    });
  }

  async handleFind(params?: RequestQueryParamsModel): Promise<void> {
    try {
      const { findAllReceivableMutation } = useInvoiceAR();
      this.loading.find = true;
      const response = await findAllReceivableMutation(params);
      this.dataMutation = response;
      this.dataSource =
        this.dataMutation.receivablesMutationLines.map<TableRow>(
          (item, key) => ({
            key,
            ...item,
          })
        );
    } catch (error) {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loading.find = false;
    }
  }

  fetchInvoiceArStatus(): void {
    const { findInvoiceArPaymentStatus } = useInvoiceAR();
    findInvoiceArPaymentStatus().then(response => {
      this.optPaymentStatus = response;
    });
  }

  fetchInvoiceType(): void {
    const { findMasterSource } = useInvoiceAR();
    findMasterSource().then(response => {
      this.optInvoiceType = response;
    });
  }
}
